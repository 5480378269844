<template>
  <div class="movimiento-stock mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Movimientos en stock
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  :items="stocks"
                  v-model="stock"
                  outlined
                  rounded
                  dense
                  label="Tipo de inventario"
                  @change="loadStock()"
                ></v-select>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="12" md="3" sm="6">
                <v-btn
                  color="success"
                  @click="
                    dialog = true;
                    tipo = 1;
                  "
                >
                  <v-icon left>mdi-upload</v-icon>
                  Generar ingreso
                </v-btn>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-btn
                  color="error"
                  @click="
                    dialog = true;
                    tipo = 2;
                  "
                >
                  <v-icon left>mdi-download</v-icon>
                  Generar salida
                </v-btn>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :search="search"
                  :footer-props="{
                    itemsPerPageText: 'Productos',
                    itemsPerPageOptions: [10, 25, 50, -1],
                  }"
                  :options="{
                    itemsPerPage: 50,
                  }"
                  :loading="loading_t"
                  :items="lista_productos"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          class="mt-3"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actual="{ item }">
                    <span v-if="item.actual < 0" style="color:red">
                      {{ item.actual }}
                    </span>
                    <span v-else>
                      {{ item.actual }}
                    </span>
                    <span></span>
                  </template>
                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field
                      type="number"
                      label="Cantidad"
                      class="mt-2"
                      rounded
                      outlined
                      dense
                      append-icon="mdi-plus"
                      prepend-inner-icon="mdi-minus"
                      @click:append="item.cantidad += 1"
                      @click:prepend-inner="resta(item)"
                      v-model.number="item.cantidad"
                      @change="changeValue(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.obs="{ item }">
                    <v-textarea
                      rows="1"
                      rounded
                      outlined
                      class="mt-2"
                      auto-grow
                      no-resize
                      v-model="item.observacion"
                      label="Observacion"
                    >
                    </v-textarea>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <h3 class="mb-4 display-1">Lista de movimientos</h3>
                <v-data-table
                  dense
                  :headers="headers_m"
                  :search="search_m"
                  :footer-props="{ itemsPerPageText: 'Movimientos' }"
                  :items="lista_movimientos"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          class="mt-3"
                          v-model="search_m"
                        ></v-text-field>
                      </v-col>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.profesional="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-on="on"
                          v-bind="attrs"
                          class="d-inline-block text-truncate"
                          style="max-width: 100px;"
                        >
                          {{ item.profesional }}
                        </span>
                      </template>
                      <span>{{ item.profesional }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.tipo="{ item }">
                    <v-chip
                      class="my-2"
                      :color="item.tipo == 'Ingreso' ? 'success' : 'error'"
                    >
                      {{ item.tipo }}
                    </v-chip>
                  </template>
                  <template v-slot:item.obs="{ item }">
                    <v-tooltip top max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-on="on"
                          v-bind="attrs"
                          class="d-inline-block text-truncate"
                          style="max-width: 100px;"
                        >
                          {{ item.obs }}
                        </span>
                      </template>
                      <span>{{ item.obs }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.estado="{ item }">
                    <v-chip
                      class="my-2"
                      :color="item.estado == 'Activo' ? 'success' : 'error'"
                    >
                      {{ item.estado }}
                    </v-chip>
                  </template>
                  <template v-slot:item.acciones="{ item }">
                    <v-tooltip top v-if="item.estado == 'Activo'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          fab
                          small
                          color="error"
                          :loading="loading"
                          :disabled="loading"
                          @click="anular_movimiento(item.id)"
                        >
                          <v-icon>mdi-cancel</v-icon>
                        </v-btn>
                      </template>
                      <span>Anular</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" persistent scrollable max-width="400px">
              <v-card>
                <v-card-title>
                  <span class="headline">Movimiento en Stock</span>
                </v-card-title>
                <v-card-text
                  ><v-row>
                    <v-col cols="12" md="12" sm="12">
                      <p v-if="tipo == 1">
                        Estas seguro de
                        <strong style="color:green">generar el ingreso</strong>
                        de los productos registrados?.
                      </p>
                      <p v-else>
                        Estas seguro de
                        <strong style="color:red">generar la salida</strong> de
                        los productos registrados?
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="
                      dialog = false;
                      tipo = null;
                    "
                    color="primary"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    @click="movimientoProductos()"
                    :dark="!loading"
                    :color="!loading ? 'black' : 'gray'"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "movimientosStock",
  data: () => ({
    loading_t: false,
    menu: false,
    dialog: false,
    tipo: null,
    stock: 1,
    stocks: [
      { text: "Stock de venta", value: 1 },
      { text: "Stock de consumo", value: 2 },
    ],
    search: "",
    search_m: "",
    headers: [
      {
        text: "Código",
        value: "codigo",
        align: "start",
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Cant actual",
        value: "actual",
      },
      {
        text: "Cantidad",
        value: "cantidad",
        sortable: false,
      },
      {
        text: "Observacion",
        value: "obs",
        sortable: false,
      },
    ],
    productos: [],
    loading: false,
    movimientos: [],
    headers_m: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Stock",
        value: "stock",
      },
      {
        text: "Código",
        value: "codigo",
        sortable: false,
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Cantidad",
        value: "cantidad",
        sortable: false,
      },
      {
        text: "Tipo",
        value: "tipo",
      },
      {
        text: "Profesional",
        value: "profesional",
        sortable: false,
      },
      {
        text: "Observación",
        value: "obs",
        sortable: false,
      },
      {
        text: "Estado",
        value: "estado",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],
  }),
  methods: {
    loadStock() {
      this.loading_t = true
      const body = {
        route: "/stock_productos",
        params: {
          stock: this.stock,
          date: {
            inicio: moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            fin: moment().format("YYYY-MM-DD"),
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      }).finally(() => (this.loading_t = false))
    },
    loadMovimientos() {
      const body = {
        route: "/movimientos",
      };

      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.movimientos = response.data.data;
        }
      });
    },
    movimientoProductos() {
      let productos = this.lista_productos.filter((p) => {
        return p.cantidad > 0;
      });
      if (productos.length > 0) {
        this.loading = true;
        const body = {
          route: "/movimiento_stock",
          data: {
            movimientos: productos.map((p) => {
              return {
                producto_id: p.id,
                producto: p.producto,
                codigo: p.codigo,
                cantidad: p.cantidad,
                stock: this.stock,
                tipo: this.tipo,
                observacion: p.observacion == "" ? null : p.observacion,
                created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              };
            }),
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              this.dialog = false;
              this.tipo = null;
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.loadStock();
              this.loadMovimientos();
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        this.dialog = false;
        this.tipo = null;
      }
    },
    anular_movimiento(id) {
      this.loading = true;
      const body = {
        route: "/anular_movimiento",
        data: {
          id: id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.tipo = null;
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadStock();
            this.loadMovimientos();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    resta(input) {
      input.cantidad -= 1;
      if (input.cantidad < 0) input.cantidad = 0;
    },
    changeValue(input) {
      if (input.cantidad == "") input.cantidad = 0;

      if (input.cantidad < 0) input.cantidad = 0;
    },
  },
  created() {
    this.loadStock();
    this.loadMovimientos();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["caja"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_productos() {
      return this.productos.map((p) => {
        return {
          producto: p.producto,
          codigo: p.codigo,
          id: p.id_activo,
          actual:
            p.inicial_stock +
            p.entradas +
            p.m_entradas -
            (p.salidas + p.m_salidas),
          cantidad: 0,
          observacion: "",
        };
      });
    },
    lista_movimientos() {
      return this.movimientos.map((m) => {
        return {
          id: m.id,
          fecha: moment(m.created_at).format("ll"),
          codigo: m.codigo,
          producto: m.producto,
          tipo: m.tipo == 1 ? "Ingreso" : "Salida",
          profesional: m.profesional,
          obs: m.observacion,
          cantidad: m.cantidad,
          estado: m.estado == 1 ? "Activo" : "Anulado",
          stock: m.stock == 1 ? "Venta" : "Consumo",
        };
      });
    },
  },
  watch: {},
};
</script>
