var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"movimiento-stock mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Movimientos en stock ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.stocks,"outlined":"","rounded":"","dense":"","label":"Tipo de inventario"},on:{"change":function($event){return _vm.loadStock()}},model:{value:(_vm.stock),callback:function ($$v) {_vm.stock=$$v},expression:"stock"}})],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.dialog = true;
                  _vm.tipo = 1;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Generar ingreso ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = true;
                  _vm.tipo = 2;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Generar salida ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"footer-props":{
                  itemsPerPageText: 'Productos',
                  itemsPerPageOptions: [10, 25, 50, -1],
                },"options":{
                  itemsPerPage: 50,
                },"loading":_vm.loading_t,"items":_vm.lista_productos},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.actual",fn:function(ref){
                var item = ref.item;
return [(item.actual < 0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.actual)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.actual)+" ")]),_c('span')]}},{key:"item.cantidad",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","label":"Cantidad","rounded":"","outlined":"","dense":"","append-icon":"mdi-plus","prepend-inner-icon":"mdi-minus"},on:{"click:append":function($event){item.cantidad += 1},"click:prepend-inner":function($event){return _vm.resta(item)},"change":function($event){return _vm.changeValue(item)}},model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", _vm._n($$v))},expression:"item.cantidad"}})]}},{key:"item.obs",fn:function(ref){
                var item = ref.item;
return [_c('v-textarea',{staticClass:"mt-2",attrs:{"rows":"1","rounded":"","outlined":"","auto-grow":"","no-resize":"","label":"Observacion"},model:{value:(item.observacion),callback:function ($$v) {_vm.$set(item, "observacion", $$v)},expression:"item.observacion"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h3',{staticClass:"mb-4 display-1"},[_vm._v("Lista de movimientos")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers_m,"search":_vm.search_m,"footer-props":{ itemsPerPageText: 'Movimientos' },"items":_vm.lista_movimientos},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_m),callback:function ($$v) {_vm.search_m=$$v},expression:"search_m"}})],1)],1)]},proxy:true},{key:"item.profesional",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.profesional)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.profesional))])])]}},{key:"item.tipo",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":item.tipo == 'Ingreso' ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.tipo)+" ")])]}},{key:"item.obs",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.obs)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.obs))])])]}},{key:"item.estado",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":item.estado == 'Activo' ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.estado)+" ")])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [(item.estado == 'Activo')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"error","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.anular_movimiento(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Anular")])]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Movimiento en Stock")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.tipo == 1)?_c('p',[_vm._v(" Estas seguro de "),_c('strong',{staticStyle:{"color":"green"}},[_vm._v("generar el ingreso")]),_vm._v(" de los productos registrados?. ")]):_c('p',[_vm._v(" Estas seguro de "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("generar la salida")]),_vm._v(" de los productos registrados? ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false;
                    _vm.tipo = null;}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.movimientoProductos()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }